import React from "react";
import Dash from "../../components/shared/dash"
import Sidebar from "../../components/common/Sidebar"

const NewDashPage = () => {
  return (
      <Dash/>
  );
};

export default NewDashPage;
